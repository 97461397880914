/* eslint-disable no-undef, react/prop-types */
import { graphql, Link, navigate } from 'gatsby';
import React, { Component } from 'react';
import { Parallax, Background } from 'react-parallax';
import { css } from '@emotion/core';
import { Helmet } from 'react-helmet';
import PageTransition from '../components/PageTransition/index';
import media from '../utils/media';
import feather from '../utils/feather';
import Hero from '../components/Hero';
import colors from '../utils/colors';
import { whiteTextShadow } from '../style/genericStyling';
import { svgStyles } from '../style/featherIconStyle';

const heading1CustomCSS = css`
  width: 68px;
  height: 68px;
  padding-top: 3px;
  padding-right: 2px;
  font: 68px Times New Roman, sans-serif;
  margin-bottom: 20px;
  border-radius: 50%;
  background: #e42b52;
  color: ${colors.primary};
  text-align: center;
  padding-left: 0px;
  font-weight: 100;
  margin-left: 38%;
  text-shadow: 4px 4px 8px #000000;
  ${media.mid`
    font-size: 88px;
    width: 88px;
    height: 88px;
    padding-top: 4px;
    padding-right: 2px;
    margin-bottom: 25px;
  `};
`;

const customIconHeader = css`
  ${media.mid`
    margin-top: -25px;
    width: 88px;
    height: 88px;
  `};
`;

const Error = ({ toggleNavDark, navDarkActive, data, location }) => {
  toggleNavDark({ navBarDark: false });
  const { heroImage } = data;
  const pageData = data.pageData.edges[0].node;
  return (
    <PageTransition location={location}>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>404 error - Page Not Found :( </title>
      </Helmet>
      <Hero
        toggleNavDark={toggleNavDark}
        navDarkActive={navDarkActive}
        color="#000000"
        opacity={0.2}
        heading1Object={feather('x', ['68', '68'], customIconHeader.styles)}
        heading1CustomCSS={heading1CustomCSS}
        heading2={pageData.title}
        heading2CustomCSS={whiteTextShadow}
        heading3={pageData.subtitle}
        heading3CustomCSS={whiteTextShadow}
        heading4={pageData.subtitle2}
        heading4CustomCSS={whiteTextShadow}
        heading1-data-aos="fade-in"
        heading1-data-aos-duration="1500"
        heading1-data-aos-delay="250"
        heading2-data-aos="fade-in"
        heading2-data-aos-delay="750"
        heading2-data-aos-duration="1500"
        heading3-data-aos="fade-in"
        heading3-data-aos-delay="1000"
        heading3-data-aos-duration="2000"
        heading4-data-aos="fade-in"
        heading4-data-aos-delay="1500"
        heading4-data-aos-duration="2500"
        heroImage={heroImage.fluid.src}
        minHeight="65vh"
      />
    </PageTransition>
  );
};
export default Error;

export const pageQuery = graphql`
  query error404ContentQuery {
    pageImages: allFile(
      filter: { sourceInstanceName: { eq: "images" }, relativeDirectory: { eq: "resources" }, internal: { mediaType: { regex: "/image/" } } }
      sort: { fields: [relativePath], order: ASC }
    ) {
      edges {
        node {
          id
          name
          relativePath
          sourceInstanceName
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 1700, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    heroImage: imageSharp(original: { src: { regex: "/errorHero/" } }) {
      fluid(maxWidth: 1500, quality: 90) {
        ...GatsbyImageSharpFluid
      }
    }
    pageData: allPageDataJson(filter: { name: { eq: "404" } }) {
      edges {
        node {
          name
          title
          subtitle
          subtitle2
        }
      }
    }
  }
`;
